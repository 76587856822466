
import type { PropType } from 'vue'
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import TmStylizedText from '@/components/shared/TmStylizedText.vue'
import copyToClipboard from 'quasar/src/utils/copy-to-clipboard.js';
import type { TextArray } from '@/definitions/shared/types'

export default defineComponent({
  components: {
    TmModal,
    TmButton,
    TmTooltip,
    TmStylizedText,
  },
  props: {
    title: {
      type: String,
    },
    chatLink: {
      type: String,
      required: true,
    },
    text: {
      type: [String, Array] as PropType<string | TextArray[]>,
      required: true,
    },
    image: {
      type: String,
    },
  },
  setup(props) {
    const copiedStatus = ref(false)
    const copyText = () => {
      copiedStatus.value = true
      copyToClipboard(props.chatLink)
      setTimeout(() => {
        copiedStatus.value = false
      }, 3000)
    }

    return {
      copiedStatus,
      copyText,
    }
  },
})
